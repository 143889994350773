import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { REACT_APP_WATCHDOG_ENDPOINT } from '../constants/constants'

class Watchdog {
  constructor() {
    this.connectionStatus = null;
    this.playerIdCb = () => {};
    this.connect();
  }

  connect() {
    this.disconnect();
    this.StompClient = Stomp.over(new SockJS(`${REACT_APP_WATCHDOG_ENDPOINT}`));
    this.StompClient.debug = null;
    this.StompClient.connect(
      {},
      this.onConnected,
      this.onConnectError,
    );
  }

  disconnect() {
    if (this.connectionStatus === 'connected') {
      this.connectionStatus = 'disconnected';
      this.StompClient.disconnect();
    }
  }

  log(type, args) {
    if (this.connectionStatus === 'connected') {
      const msg = JSON.stringify({
        type,
        content: JSON.stringify(args),
        timestamp: new Date(),
      });
      this.StompClient.send('/app/player_logging', {}, msg);
    }
  }

  onConnected = frame => {
    console.log('Connected: ' + frame);
    this.connectionStatus = 'connected';
    this.isConnected = true;
    this.StompClient.subscribe('/player/id', this.onPlayerId);
    this.StompClient.send('/app/player_id');
  };

  onConnectError = () => {
    // console.error('WP => NO CONNECTION WATCHDOG');
    this.connectionStatus = 'error';
    setTimeout(() => {
      this.connect();
    }, 30000);
  };

  onPlayerId = message => {
    const event = JSON.parse(message.body);
    this.playerIdCb(event.content);
  };

  requestPlayerId = cb => {
    this.playerIdCb = cb;
    if (this.connectionStatus === 'connected') {
      this.StompClient.send('/app/player_id');
    } else {
      this.playerIdCb(null);
    }
  };
}

const wd = new Watchdog();
export default wd;

/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import moment from 'moment';
import Watchdog from '../services/Watchdog';
import {
  CONFIGURATION_REQUEST,
  CONFIGURATION_UPDATE,
  HEARTBEAT,
  WATCHDOG_LOG_REQUEST,
} from '../constants/eventTypes';
import { WEBCONTAINER_HEARTBEAT, WEBCONTAINER_UPDATE } from '../constants/actionTypes';
import { REACT_APP_WEBCONTAINER_HEARTBEAT_INTERVAL } from '../constants/constants'

export const updateWebcontainer = frameId => {
  return dispatch => {
    const webcontainer = {
      frameId,
      heartbeatInterval: REACT_APP_WEBCONTAINER_HEARTBEAT_INTERVAL,
    };
    dispatch(webcontainterUpdate(webcontainer));
    dispatch(sendConfigurationUpdate(frameId));
  };
};

export const sendConfigurationUpdate = frameId => {
  return (dispatch, getState) => {
    const { creatives, frames, session, tags, webcontainers } = getState();
    const playerId = session.playerId;
    const frame = frames[frameId];
    const tag = tags[frame.tagId];
    const webcontainer = webcontainers[frameId];
    const fallbackContent = tag ? tag.fallbackContent : {};
    const creative = fallbackContent ? creatives[fallbackContent.creativeId] : null;
    const fallback = creative
      ? {
          id: creative.id,
          url: creative.url,
          duration: creative.duration,
          mimeType: creative.mimeType,
        }
      : null;
    const payload = {
      frameId,
      playerId,
      resolution: {
        width: String(document.getElementById(frameId).clientWidth),
        height: String(document.getElementById(frameId).clientHeight),
      },
      fallback,
      heartbeatInterval: webcontainer.heartbeatInterval,
    };
    const message = {
      eventType: CONFIGURATION_UPDATE,
      targetFrameId: frameId,
      payload,
    };
    sendPostMessage(frameId, message);
  };
};

const webcontainterUpdate = config => {
  return {
    type: WEBCONTAINER_UPDATE,
    payload: config,
  };
};

const webcontainerHeartbeat = frameId => {
  const heartbeat = moment();
  return {
    type: WEBCONTAINER_HEARTBEAT,
    payload: { frameId, heartbeat },
  };
};

export const isWebcontainerHeartbeatExpired = frameId => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { player, webcontainers } = getState();
      const webcontainer = webcontainers[frameId];
      if (webcontainer.heartbeat && player.opened) {
        const heartbeat = moment(webcontainer.heartbeat);
        const diff = moment().diff(heartbeat);
        const maxDiff = REACT_APP_WEBCONTAINER_HEARTBEAT_INTERVAL * 3;
        return resolve(diff > maxDiff);
      } else {
        return resolve(false);
      }
    });
  };
};

const sendPostMessage = (frameId, message) => {
  const win = document.getElementById(frameId).contentWindow;
  if (win) {
    win.postMessage(message, '*');
  }
};

export const onPostMessage = messageEvent => {
  return (dispatch, getstate) => {
    const { eventType, frameId, payload } = messageEvent.data;
    switch (eventType) {
      // iframe request to receive component data
      case CONFIGURATION_REQUEST: {
        dispatch(sendConfigurationUpdate(frameId));
        break;
      }
      case HEARTBEAT: {
        dispatch(webcontainerHeartbeat(frameId));
        // console.info(`Heartbeat received from frameId: ${frameId}`);
        break;
      }
      case WATCHDOG_LOG_REQUEST: {
        Watchdog.log(payload.method, { frameId, args: payload.args });
        break;
      }
      default:
    }
  };
};

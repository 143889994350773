/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import difference from 'lodash/difference';
import { FRAME_DELETE, FRAME_LOAD_SUCCESS } from '../constants/actionTypes';
import { firebaseDb } from '../services/firebase';
import { loadTag } from './tags';
import { updateWebcontainer } from './webcontainers';
import { REACT_APP_FIREBASE_DB_PATH_CONFIG } from '../constants/constants'

export const loadframes = () => {
  return (dispatch, getState) => {
    const { screenLayout } = getState();
    const frameIds = screenLayout.frameIds;
    frameIds.forEach(frameId => {
      const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/frames/${frameId}`;
      return firebaseDb()
        .ref(firebasePath)
        .on('value', snapshot => {
          const frame = snapshot.val();
          if (frame) {
            dispatch(frameLoadSuccess(frame));
            dispatch(updateWebcontainer(frame.id));
            dispatch(loadTag(frame.id, frame.tagId));
          }
        });
    });
    dispatch(removeUnusedFrames());
  };
};

const removeUnusedFrames = () => {
  return (dispatch, getState) => {
    const { frames, screenLayout } = getState();
    const allFrames = Object.keys(frames);
    const usedFrameIds = screenLayout.frameIds;
    const unusedFrames = difference(allFrames, usedFrameIds);
    unusedFrames.forEach(frameId => {
      const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/frames/${frameId}`;
      firebaseDb()
        .ref(firebasePath)
        .off();
      dispatch(frameDelete(frameId));
    });
  };
};

const frameLoadSuccess = frame => {
  return {
    type: FRAME_LOAD_SUCCESS,
    payload: frame,
  };
};

const frameDelete = id => {
  return {
    type: FRAME_DELETE,
    payload: { id },
  };
};

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from './components/AsyncComponent';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

const General = asyncComponent(() => import('./scenes/General'));
const Login = asyncComponent(() => import('./scenes/Login'));
// const NotFound = asyncComponent(() => import('./scenes/NotFound'));
const ClearLocalstorage = asyncComponent(() => import('./scenes/ClearLocalstorage'));

export default ({ childProps }) => {
  let driverComponent = General;
  return (
    <Switch>
      <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
      <AuthenticatedRoute path="/" exact component={driverComponent} props={childProps} />
      {/* <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
      <UnauthenticatedRoute path="/login/:id" exact component={Login} props={childProps} />
      <UnauthenticatedRoute path="/test" exact component={Test} props={childProps} />
      <AuthenticatedRoute path="/" exact component={driverComponent} props={childProps} /> */}
      {/* <UnauthenticatedRoute path="/:id" exact component={Login} props={childProps} /> */}
      {/* Finally, catch all unmatched routes */}
      {/* <Route component={Login} props={childProps} /> */}
      <Route path="/clear" exact component={ClearLocalstorage} />
      <Redirect from='*' to='/' />
    </Switch>
  );
};

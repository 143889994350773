/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import {
  PLAYER_LOAD_SUCCESS,
  PLAYER_SET_OPENED_STATUS,
  PLAYER_SET_CONDITIONS,
  PLAYER_SET_TRIGGER,
} from '../constants/actionTypes';

export default function player(state = null, action) {
  switch (action.type) {
    case PLAYER_LOAD_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case PLAYER_SET_OPENED_STATUS: {
      return { ...state, opened: action.payload };
    }
    case PLAYER_SET_CONDITIONS: {
      const conditions = action.conditions;
      const newConditions = { ...state.conditions, ...conditions };
      Object.keys(newConditions).forEach(key => {
        if (!newConditions[key]) {
          delete newConditions[key];
        }
      });
      console.log(newConditions);
      return { ...state, conditions: newConditions };
    }
    case PLAYER_SET_TRIGGER: {
      const trigger = action.trigger;
      return { ...state, trigger };
    }
    default:
      return state;
  }
}

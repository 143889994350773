/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import { TAG_DELETE, TAG_LOAD_SUCCESS } from '../constants/actionTypes';

const initialState = {};

export default function tags(state = initialState, action) {
  switch (action.type) {
    case TAG_DELETE: {
      const newState = { ...state };
      const { id } = action.payload;
      delete newState[id];
      return newState;
    }
    case TAG_LOAD_SUCCESS: {
      const tag = {};
      tag[action.payload.id] = { ...action.payload };
      return { ...state, ...tag };
    }
    default:
      return state;
  }
}

/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
// import firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import isEqual from 'lodash/isEqual';

let fbConfig;
let fbApp;
let isNotConnected = true;
export const firebaseInit = async (config, successCallback, errorCallback) => {
    // create firebase app for player
    if (!isEqual(config.primaryFirebase.cfg, fbConfig)) {
      console.log('WP FIREBASE CONFIG RECEIVED FROM CMDB');
      fbConfig = config.primaryFirebase.cfg;
      if (fbApp) {
        await fbApp.delete();
        console.log('Firebase delete app');
      }
    }
    if (fbApp) {
      console.info('WP FIREBASE SIGN IN SUCCESFUL');
      // Is this valid? If there is a fbApp, there is a connection as well?
      return successCallback();
    }

    fbApp = firebase.initializeApp(fbConfig);
    console.log('WP INITIALIZE FIREBASE');

    const connectedRef = firebase.database().ref('.info/connected');
    connectedRef.on('value', async snap => {
      const connected = snap.val();
      const projectId = config.primaryFirebase.cfg.projectId;
      if (connected === true) {
        // FB needs milliseconds to setup connection, a timeout of 10 seconds is used.
        isNotConnected = false;

        const token = config.primaryFirebase.jwt;
        try {
          await firebase.auth().signInWithCustomToken(token);
          console.info('WP FIREBASE SIGN IN SUCCESFUL =>', projectId);
          successCallback();
        } catch (e) {
          console.error('WP FIREBASE SIGN IN FAILED =>', projectId);
          errorCallback(new Error('WP FIREBASE SIGN IN FAILED =>' + e.code + '=' + e.message));
          // If login to Firebase fails, try again in 30 minutes.
          setTimeout(() => {
            console.warn('WP TRY SIGN IN FIREBASE AGAIN')
            firebaseInit(config, successCallback, errorCallback);
          }, 30 * 60 * 1000);
        }
      } else {
        setTimeout(() => {
          // FB needs milliseconds to setup connection, a timeout of 10 seconds is used.
          if (isNotConnected) {
            console.warn('WP FIREBASE TIMEOUT EXCEEDED TO SETUP CONNECTION =>', projectId);
            errorCallback(new Error('WP FIREBASE SIGN IN FAILED!' ));
          }
        }, 10 * 1000);
      }
    });
};

export const firebaseDb = () => firebase.database();

export default firebase;

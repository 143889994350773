import difference from 'lodash/difference';
import { TAG_DELETE, TAG_LOAD_SUCCESS } from '../constants/actionTypes';
import { firebaseDb } from '../services/firebase';
import { loadCreative } from './creatives';
import { REACT_APP_FIREBASE_DB_PATH_CONFIG } from '../constants/constants';

export function loadTag(frameId, tagId) {
  return (dispatch, getState) => {
    const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/tags/${tagId}`;
    firebaseDb()
      .ref(firebasePath)
      .on('value', snapshot => {
        const tag = snapshot.val();
        if (tag) {
          dispatch(tagLoadSuccess(tag));
          if (tag.fallbackContent) {
            dispatch(loadCreative(frameId, tag.fallbackContent.creativeId));
          }
        }
        dispatch(removeUnusedTags());
      });
  };
}

const removeUnusedTags = () => {
  return (dispatch, getState) => {
    const { frames, tags } = getState();
    const allTags = Object.keys(tags);
    const usedTagIds = [];
    Object.keys(frames).forEach(key => usedTagIds.push(frames[key].tagId));
    const unusedTags = difference(allTags, usedTagIds);
    unusedTags.forEach(tagId => {
      const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/tags/${tagId}`;
      firebaseDb()
        .ref(firebasePath)
        .off();
      dispatch(tagDelete(tagId));
    });
  };
};

const tagLoadSuccess = tag => {
  return {
    type: TAG_LOAD_SUCCESS,
    payload: tag,
  };
};

const tagDelete = id => {
  return {
    type: TAG_DELETE,
    payload: { id },
  };
};

import { firebaseInit } from '../services/firebase';
import { SESSION_INITIALIZE_SUCCESS } from '../constants/actionTypes';
import { setupPlayer } from './player';
import { getCmdbFbEndpoint } from '../endpoints'

const getConfig = (playerId, configCache) => {
  const authEndpoint = getCmdbFbEndpoint();
  const configBase = {
    authEndpoint,
  };
  let config;
  if (playerId) {
    if (configCache && configCache.playerId === playerId) {
      config = Object.assign({}, configCache, configBase);
    } else {
      config = Object.assign({}, configCache, configBase, { playerId });
    }
  } else {
    config = Object.assign({}, configCache, configBase);
  }
  return config;
};

export const sessionInitialize = ({playerId, reCaptchaToken}) => {
  return async (dispatch, getState) => {
    const configCache = getState().session;
    const config = getConfig(playerId, configCache);

    return new Promise(async (resolve, reject) => {
      if (!config || !config.playerId) {
        return reject(false);
      }
      let fbConfig;
      try {
        const formData = new FormData();
        formData.append('id', config.playerId);
        formData.append('reCaptchaToken', reCaptchaToken);
        const response = await fetch(config.authEndpoint, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          return reject(false);
        }
        fbConfig = await response.json();
      } catch (error) {
        // no internet connection, if there is a firebase configuration use this one
        if (config.fbConfig) {
          fbConfig = config.fbConfig;
        } else {
          console.log(error);
          return reject(false);
        }
      }
      // Create a succes callback. Firebase is listening so a Promise can't be used.
      const successCallback = () => {
        const newConfig = {...config, fbConfig};
        localStorage.setItem('configuration', JSON.stringify(newConfig));
        dispatch(sessionInitializeSuccess(newConfig));
        dispatch(setupPlayer());
        return resolve(true);
      };

       // Create an error callback.
      const errorCallback = error => {
        return reject(error);
      };
      await firebaseInit(fbConfig, successCallback, errorCallback);
    });
  };
};

export const sessionInitializeSuccess = config => {
  return { type: SESSION_INITIALIZE_SUCCESS, payload: config };
};

/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import difference from 'lodash/difference';
import { CREATIVE_DELETE, CREATIVE_LOAD_SUCCESS } from '../constants/actionTypes';
import { firebaseDb } from '../services/firebase';
import { sendConfigurationUpdate } from './webcontainers';
import { REACT_APP_FIREBASE_DB_PATH_CONFIG } from '../constants/constants'

export const loadCreative = (frameId, creativeId) => {
  return (dispatch, getState) => {
    const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/creatives/${creativeId}`;
    firebaseDb()
      .ref(firebasePath)
      .on('value', snapshot => {
        const creative = snapshot.val();
        if (creative) {
          dispatch(creativeLoadSuccess(creative));
          dispatch(sendConfigurationUpdate(frameId));
        }
        dispatch(removeUnusedCreatives());
      });
  };
};

const removeUnusedCreatives = () => {
  return (dispatch, getState) => {
    const { creatives, tags } = getState();
    const allCreatives = Object.keys(creatives);
    const usedCreativeIds = [];
    Object.keys(tags).forEach(key => {
      const tag = tags[key];
      if (tag.fallbackContent) {
        usedCreativeIds.push(tag.fallbackContent.creativeId);
      }
    });
    const unusedCreatives = difference(allCreatives, usedCreativeIds);
    unusedCreatives.forEach(creativeId => {
      const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/creatives/${creativeId}`;
      firebaseDb()
        .ref(firebasePath)
        .off();
      dispatch(creativeDelete(creativeId));
    });
  };
};

const creativeLoadSuccess = creative => {
  return {
    type: CREATIVE_LOAD_SUCCESS,
    payload: creative,
  };
};

const creativeDelete = id => {
  return {
    type: CREATIVE_DELETE,
    payload: { id },
  };
};

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Watchdog from './services/Watchdog';
import { getPlayerId } from './services/utilities';
import Routes from './Routes';
import { validateOpeningHours } from './actions/player';
import { sessionInitialize } from './actions/session';
import { onPostMessage } from './actions/webcontainers';
import { version, name } from '../package.json';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticating: true,
    };
    this.playerId = null;
    window.addEventListener('message', this.props.onPostMessage);
  }

  componentDidMount() {
    // Do not execute the methods if user wants to clear things.
    if (window.location.pathname === '/clear') {
      this.setState({
        isAuthenticating: false
      });
    } else {
      this.props.validateOpeningHours();
      Watchdog.requestPlayerId(this.requestPlayerIdCallback);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.props.onPostMessage);
  }

  requestPlayerIdCallback = result => {
    let playerId;
    if (!result) {
      // Give priority to playerId in url(getPlayerId()) so webplayer url can be called directly from UI CMDB.
      playerId = getPlayerId() || this.props.playerId;
    } else {
      playerId = result;
    }
    if (this.playerId !== playerId) {
      this.playerId = playerId;
      this.props
        .sessionInitialize({playerId})
        .then(result => {
          this.setState({ isAuthenticating: false });
        })
        .catch(e => {
          this.setState({ isAuthenticating: false });
        });
    }
    if (!playerId) {
      this.setState({ isAuthenticating: false });
    }
  };

  render() {
    console.log(`WP VERSION => ${name}@${version}`);
    const childProps = {
      isAuthenticated: this.props.isAuthenticated,
    };
    if (this.state.isAuthenticating) return null;
    return (
      <div className="App">
        <Routes childProps={childProps} />
      </div>
    );
  }
}

const mapStateToProps = ({ player, session }, ownProps) => ({
  playerId: player ? player.id : null,
  isAuthenticated: session.isAuthenticated,
});

const mapDispatchToProps = dispatch => {
  return {
    onPostMessage: messageEvent => dispatch(onPostMessage(messageEvent)),
    sessionInitialize: config => dispatch(sessionInitialize(config)),
    validateOpeningHours: () => dispatch(validateOpeningHours()),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);

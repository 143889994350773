import { FRAME_DELETE, FRAME_LOAD_SUCCESS } from '../constants/actionTypes';

const initialState = {};

export default function frame(state = initialState, action) {
  switch (action.type) {
    case FRAME_DELETE: {
      const newState = { ...state };
      const { id } = action.payload;
      delete newState[id];
      return newState;
    }
    case FRAME_LOAD_SUCCESS: {
      const frame = {};
      frame[action.payload.id] = { ...action.payload };
      return { ...state, ...frame };
    }
    default:
      return state;
  }
}

import moment from 'moment-timezone';
import {
  PLAYER_LOAD_SUCCESS,
  PLAYER_SET_OPENED_STATUS,
} from '../constants/actionTypes';
import {
  REACT_APP_FIREBASE_DB_PATH_CONFIG,
  REACT_APP_FIREBASE_DB_PATH_MONITORING,
  REACT_APP_FIREBASE_DB_PATH_COMMANDS,
} from '../constants/constants'
import { loadScreenLayout } from './screenLayout';
import firebase, { firebaseDb } from '../services/firebase';
import { os, browser } from '../services/systemSpecs';

export const setupPlayer = () => (dispatch, getState) => {
  const { session } = getState();
  let playerId = session.playerId;
  const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/players/${playerId}`;
  firebaseDb()
    .ref(firebasePath)
    .on('value', snapshot => {
      const player = snapshot.val();
      if (player) {
        console.log('WP => PLAYER FOUND IN FIREBASE')
        setSupportReportingInterval(playerId);
        setBrowserRestartTimeout();
        setUpFirebaseCommandListener(playerId);
        dispatch(playerLoadSuccess(player));
        dispatch(validateOpeningHours());
        dispatch(loadScreenLayout());
      } else {
        console.error('WP => NO PLAYER FOUND IN FIREBASE')
      }
    });
};

let intervalOpeninghours;
export const validateOpeningHours = () => (dispatch, getState) => {
  const { player } = getState();
  const openingHours = player?.openingHours || {};
  const timezone = player?.timezone || null;
  const status = findOpeningStatus(openingHours, timezone);

  dispatch(playerSetOpenedStatus(status.opened));
  console.info('WP VALIDATED OPENINGHOURS =>', status.opened ? 'OPEN' : 'CLOSED');
  const maxDelay = 15 * 60 * 1000;
  if (status.duration <= maxDelay) {
    let timeoutOpeninghours = setTimeout(() => {
      clearTimeout(timeoutOpeninghours);
      dispatch(validateOpeningHours());
    }, status.duration);
  } else {
    if (!intervalOpeninghours) {
      intervalOpeninghours = setInterval(() => {
        dispatch(validateOpeningHours());
      }, maxDelay);
      // console.info('validateOpeningHours: added interval', intervalOpeninghours, maxDelay);
    }
  }
};

const findOpeningStatus = (openingHours, timezone) => {
  let start, end, duration;
  let opened = false;

  if (timezone) {
    moment.tz.setDefault(timezone);
  }
  
  const dayKey = Object.keys(openingHours).find(key => {
    const dayElement = openingHours[key];
    if (dayElement.items) {
      // Sort the hours from early to late.
      const dayTimes = dayElement.items.sort((a, b) => (a.startHour > b.startHour) ? 1 : -1)
      return dayTimes.find(openingElement => {
        start = moment()
          .day(dayElement.dayNbr)
          .hours(openingElement.startHour.split(':')[0])
          .minute(openingElement.startHour.split(':')[1])
          .seconds(0);
        end = moment(start)
          .add(openingElement.duration, 'minutes')
          .subtract(1, 'seconds');
        /*
         ** There is a small bug in the openinghours send by the backend.
         ** End time 24:00 is stopping at 23:58:59.
         ** Quick fix is to add 1 minute if the minutes of the end time is 58.
         */
        if (end && end.format('mm') === '58') {
          end.add(1,'minutes')
        }
        if (moment().isSameOrAfter(start) && moment().isBefore(end)) {
          opened = true;
          duration = end.diff(moment());
          return true;
        } else if (start.isAfter(moment())) {
          opened = false;
          duration = start.diff(moment());
          return true;
        } else {
          return false;
        }
      });
    }
    return null;
  });

  // No opening time found for this week, set opening time to first element next week.
  if (!dayKey) {
    const firstkey = Object.keys(openingHours)[0];
    if (firstkey) {
      const dayElement = openingHours[Object.keys(openingHours)[0]];
      /*
       ** If no items, player is closed forever.
       ** When user changes openinghours in UI/Firebase,
       ** player will open again.
       */
      if (dayElement.items && dayElement.items[0]) {
        start = moment()
          .day(dayElement.dayNbr)
          .add(7, 'days')
          .hours(dayElement.items[0].startHour.split(':')[0])
          .minute(dayElement.items[0].startHour.split(':')[1])
          .seconds(0);
        end = moment(start)
          .add(dayElement.items[0].duration, 'minutes')
          .subtract(1, 'seconds');
        duration = start.diff(moment());
      }
    }
  }
  return { opened, duration, start, end };
};

const playerSetOpenedStatus = status => {
  return {
    type: PLAYER_SET_OPENED_STATUS,
    payload: status,
  };
};

const playerLoadSuccess = player => {
  return {
    type: PLAYER_LOAD_SUCCESS,
    payload: player,
  };
};

const setUpFirebaseCommandListener = playerId => {
  let listener = firebaseDb().ref(`${REACT_APP_FIREBASE_DB_PATH_COMMANDS}/${playerId}`);
  listener.on('value', snapshot => {
    const command = snapshot.val();
    if (command) {
      switch (command.commandType) {
        case 'RESTART_WEBPLAYER': {
          const existsInLocalStorage = localStorage.getItem(`command_${command.commandType}`);
          // check if command is already in local storage
          if (existsInLocalStorage) {
            let updates = {};
            updates[`${playerId}`] = null;
            firebaseDb()
              .ref(`${REACT_APP_FIREBASE_DB_PATH_COMMANDS}`)
              .update(updates);
            localStorage.removeItem(`command_${command.commandType}`);
            console.info(`Finished command ${command.commandType}`);
          } else {
            // push command to local storage and restart browser
            localStorage.setItem(`command_${command.commandType}`, true);
            console.info(`Processing command ${command.commandType}`);
            window.location.reload();
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  });
};

// Setup heartbeat interval!
let supportReportingInterval = null;
const setSupportReportingInterval = playerId => {
  let updates = {};
  if (!supportReportingInterval) {
    updates['upTimeStamp'] = firebase.database.ServerValue.TIMESTAMP;
  }
  updates['heartbeat'] = firebase.database.ServerValue.TIMESTAMP;
  updates['version'] = process.env.REACT_APP_VERSION;
  updates['browser'] = `${browser.name} ${browser.version}`;
  updates['os'] = `${os.name} ${os.version}`;
  console.info('WP => SENDING HEARTBEAT', moment().format('DD-MM-YYYY HH:mm:ss'));
  firebaseDb()
    .ref(`${REACT_APP_FIREBASE_DB_PATH_MONITORING}/${playerId}`)
    .update(updates);
  if (!supportReportingInterval) {
    supportReportingInterval = setInterval(() => {
      setSupportReportingInterval(playerId);
    }, 10 * 60 * 1000);
  }
};

// Get a random number.
const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

// Get time till next restart to setTimeout.
const getTimeToRestart = () => {
  const now = moment();
  /*
   ** Get a random number of minutes in the hour to restart the browser.
   ** This will prevent a large number of connection requests to Firebase at the same time.
   */
  const randomMinutes = getRandomInt(60);
  let restartTime = moment(new Date().setHours(4, randomMinutes, 0));
  if (restartTime.isBefore(now)) {
    restartTime.add(1, 'day');
  }
  let timeToRestart = moment.duration(restartTime.diff(now));
  return timeToRestart;
};

// Reload browser window.
const restartBrowser = () => {
  console.warn('Restarting in 5 seconds...');
  setTimeout(() => {
    window.location.reload();
  }, 5000);
};

/*
 ** Reload the browser window every night at 04:00 to
 ** prevent Chrome from crashing because of memory issues.
 */
const setBrowserRestartTimeout = () => {
  const timeToNextRestart = getTimeToRestart();
  setTimeout(() => {
    restartBrowser();
  }, timeToNextRestart.asMilliseconds());
};

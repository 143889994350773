/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import { SCREENLAYOUT_LOAD_SUCCESS } from '../constants/actionTypes';

export default function screenLayout(state = null, action) {
  switch (action.type) {
    case SCREENLAYOUT_LOAD_SUCCESS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

const CMDB_URL_FB_DEV = 'https://dtest.api.cmdb.csdm.cloud/webplayer/auth/firebase'
const CMDB_URL_FB_QA = 'https://qa.api.cmdb.csdm.cloud/webplayer/auth/firebase'
const CMDB_URL_FB_STAGING = 'https://staging.api.cmdb.csdm.cloud/webplayer/auth/firebase'
const CMDB_URL_FB_PREPROD = 'https://preprod.api.cmdb.csdm.cloud/webplayer/auth/firebase'
const CMDB_URL_FB_PROD = 'https://api.cmdb.csdm.cloud/webplayer/auth/firebase'

export const getCmdbFbEndpoint = () => {
  const env = window._env_.ENVIRONMENT;
  const cmdbEndpoint = window._env_.CMDB_ENDPOINT

  if (cmdbEndpoint) {
    return cmdbEndpoint
  }

  switch (env) {
    case 'production':
      return CMDB_URL_FB_PROD;
    case 'staging':
      return CMDB_URL_FB_STAGING;
    case 'qa':
      return CMDB_URL_FB_QA;
    case 'preprod':
      return CMDB_URL_FB_PREPROD
    default:
      return CMDB_URL_FB_DEV;
  }
};

// const LOCAL_WEBCONTAINER = 'http://localhost:3001/webplayer'
const ADSERVER_URL_DEV = 'https://dtest.adserver.csdm.cloud/webplayer';
// const ADSERVER_URL_QA = 'https://qa.adserver.csdm.cloud/webplayer';
// const ADSERVER_URL_STAGING = 'https://staging.adserver.csdm.cloud/webplayer';
// const ADSERVER_URL_PROD = 'https://adserver.csdm.cloud/webplayer';

export const getAdserverEndpoint = () => {
  const url = window._env_.BACKEND_URL;
  if (url) {
    return `${url}/webplayer`;
  }
  return ADSERVER_URL_DEV;
};

import { combineReducers } from 'redux';

import creatives from './creatives';
import frames from './frames';
import player from './player';
import screenLayout from './screenLayout';
import session from './session';
import tags from './tags';
import webcontainers from './webcontainers';

const rootReducer = combineReducers({
  creatives,
  frames,
  player,
  screenLayout,
  session,
  tags,
  webcontainers,
});

export default rootReducer;

export const getPlayerId = () => {
  const { location } = window
  const searchParams = new URLSearchParams(location.search)
  const playerId = searchParams.get('playerId')
  const key = searchParams.get('key')
  if (playerId) {
    return playerId;
  }
  if (key) {
    return key;
  }
  // Check localstorage for playerId if it cannot be found in the url.
  const configuration = JSON.parse(window.localStorage.getItem('configuration'));
  if (configuration && configuration.playerId !== undefined) {
    return configuration.playerId;
  }
  return null;
}

export const getFrameId = () => {
  const { location } = window
  const searchParams = new URLSearchParams(location.search)
  const frameId = searchParams.get('frameId')
  return frameId;
}

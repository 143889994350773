/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import { SCREENLAYOUT_LOAD_SUCCESS } from '../constants/actionTypes';
import { firebaseDb } from '../services/firebase';
import { loadframes } from './frames';
import { REACT_APP_FIREBASE_DB_PATH_CONFIG } from '../constants/constants'

export const loadScreenLayout = () => (dispatch, getState) => {
  const { player, screenLayout } = getState();
  const screenLayoutId = player.screenLayoutId;

  // remove firebase listener for the old screenlayout
  if (screenLayout && screenLayoutId !== screenLayout.id) {
    const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/screenLayouts/${screenLayout.id}`;
    firebaseDb()
      .ref(firebasePath)
      .off();
  }

  const firebasePath = `${REACT_APP_FIREBASE_DB_PATH_CONFIG}/screenLayouts/${screenLayoutId}`;
  firebaseDb()
    .ref(firebasePath)
    .on('value', snapshot => {
      const screenLayout = snapshot.val();
      if (screenLayout) {
        console.log('WP => SCREENLAYOUT RECEIVED')
        dispatch(screenLayoutLoadSuccess(screenLayout));
        dispatch(loadframes());
      } else {
        console.error('WP => NO SCREENLAYOUT')
      }
    });
};

const screenLayoutLoadSuccess = screenLayout => {
  return {
    type: SCREENLAYOUT_LOAD_SUCCESS,
    payload: screenLayout,
  };
};

/* eslint no-console: 0 */ // --> CONSOLE WARNINGS OFF
import { WEBCONTAINER_HEARTBEAT, WEBCONTAINER_UPDATE } from '../constants/actionTypes';

export default function webcontainers(state = null, action) {
  switch (action.type) {
    case WEBCONTAINER_HEARTBEAT: {
      const webcontainer = {};
      webcontainer[action.payload.frameId] = {
        ...state[action.payload.frameId],
        ...action.payload,
      };
      return { ...state, ...webcontainer };
    }
    case WEBCONTAINER_UPDATE: {
      const webcontainer = {};
      webcontainer[action.payload.frameId] = action.payload;
      return { ...state, ...webcontainer };
    }
    default:
      return state;
  }
}

export const CREATIVE_DELETE = 'CREATIVE_DELETE';
export const CREATIVE_LOAD_SUCCESS = 'CREATIVE_LOAD_SUCCESS';

export const FRAME_DELETE = 'FRAME_DELETE';
export const FRAME_LOAD_SUCCESS = 'FRAME_LOAD_SUCCESS';

export const PLAYER_LOAD_SUCCESS = 'PLAYER_LOAD_SUCCESS';
export const PLAYER_SET_OPENED_STATUS = 'PLAYER_SET_OPENED_STATUS';
export const PLAYER_SET_CONDITIONS = 'PLAYER_SET_CONDITIONS';
export const PLAYER_SET_TRIGGER = 'PLAYER_SET_TRIGGER';

export const SCREENLAYOUT_LOAD_SUCCESS = 'SCREENLAYOUT_LOAD_SUCCESS';

export const SESSION_INITIALIZE_SUCCESS = 'SESSION_INITIALIZE_SUCCESS';

export const TAG_DELETE = 'TAG_DELETE';
export const TAG_LOAD_SUCCESS = 'TAG_LOAD_SUCCESS';

export const WEBCONTAINER_HEARTBEAT = 'WEBCONTAINER_HEARTBEAT';
export const WEBCONTAINER_UPDATE = 'WEBCONTAINER_UPDATE';

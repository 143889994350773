import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './services/Watchdog';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

window.recaptchaOptions = {
  enterprise: true,
};

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register({
  onUpdate: registration => {
    console.warn(
      'WP: Received a service worker update, unregister old service worker and restart browser',
    );
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: () => {
    console.warn('WP: New service worker loaded!');
  },
});
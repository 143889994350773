import { SESSION_INITIALIZE_SUCCESS } from '../constants/actionTypes';

const initialState = {};

const session = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_INITIALIZE_SUCCESS:
      return { ...state, ...action.payload, isAuthenticated: true };
    default:
      return state;
  }
};

export default session;

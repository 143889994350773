import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import throttle from 'lodash/throttle';
// _.throttle
import rootReducer from './reducers';
import { loadState, saveState } from './services/localStorage';

let middlewares = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(
  throttle(() => {
    saveState({
      ...store.getState(),
    });
  }, 1000),
);
export default store;
